























import Vue from 'vue';

import Field from '@/components/Field.vue';
import SelectField from '@/components/SelectField.vue';
import Parameters from '@/components/Postback/Parameters/Tree.vue';

import actions from '@/helpers/const/actions';
import sendTypes from '@/helpers/const/sendTypes';

export default Vue.extend({
    name: 'Item',
    props: {
        item: {
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            actions,
            sendTypes
        };
    },
    methods: {
        deleteItem() {
            this.$emit('delete', this.index);
        }
    },
    components: {
        Field,
        SelectField,
        Parameters
    }
});
