

























import Vue from 'vue';

import Field from '@/components/Field.vue';
import SelectField from '@/components/SelectField.vue';

import actions from '@/helpers/const/actions';
import sendTypes from '@/helpers/const/sendTypes';

import Validator from '@/helpers/validator';

export default Vue.extend({
    name: 'Form',
    data() {
        return {
            form: {
                action: 'registration',
                send_type: 'GET',
                uri: ''
            },
            formRules: {
                action: 'required',
                send_type: 'required',
                uri: 'required|min:1|max:255'
            },
            errors: {
                action: '',
                send_type: '',
                uri: ''
            },
            actions,
            sendTypes,
            formRequest: false,
            formWatcher: () => { return; }
        };
    },
    methods: {
        validate(submit = true) {
            const validate = new Validator(this.form, this.formRules).make();

            this.errors = validate.errors;

            if (validate.isValid && submit) {
                this.add();
            } else {
                this.formRequest = true;
            }
        },
        add() {
            this.$emit('add', {...this.form, parameters: [], key: this.uniqId});

            this.$store.commit('uniq/updateId');
            this.form = {
                action: 'registration',
                send_type: 'GET',
                uri: ''
            };
            this.formRequest = false;
        }
    },
    computed: {
        uniqId(): number {
            return this.$store.state.uniq.lastId;
        }
    },
    watch: {
        formRequest(val) {
            if (val) {
                this.formWatcher = this.$watch('form', () => {
                    this.validate(false);
                }, {
                    deep: true
                });
            } else {
                this.formWatcher();
            }
        }
    },
    components: {
        Field,
        SelectField
    }
});
