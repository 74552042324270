var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"l-search",class:{
        'l-search--active': _vm.active
    }},[_c('div',{staticClass:"l-search__field field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"input",attrs:{"type":"text","autocomplete":"false"},domProps:{"value":(_vm.search)},on:{"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.blur()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.up()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.down()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.choose()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.del.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),(!_vm.search)?[_c('div',{staticClass:"l-search__title"},[(_vm.model && _vm.model.login)?[_vm._v(" "+_vm._s(_vm.model.login)+" ")]:[_vm._v(" Не выбран ")]],2)]:_vm._e()],2),(_vm.active)?_c('div',{staticClass:"l-search__content"},[(_vm.items.length > 0)?_c('div',[_c('ul',{staticClass:"l-search__list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:item.id,staticClass:"l-search__item",class:{
                            'l-search__active': item.id === _vm.model.id,
                            'l-search__hover': _vm.hover === index
                        },on:{"mouseover":function($event){_vm.hover = index},"click":function($event){$event.stopPropagation();return _vm.choose()}}},[_vm._v(" "+_vm._s(item.login)+" "),_c('span',{staticClass:"l-search__delete",on:{"click":function($event){$event.stopPropagation();return _vm.remove(item.id)}}},[_vm._v("Удалить")])])}),0),_c('div',{staticClass:"l-search__item",on:{"click":function($event){return _vm.open()}}},[_c('span',{staticClass:"link l-search__add-new"},[_vm._v(" Добавить ")])])]):_c('div',{staticClass:"l-search__empty"},[_c('p',[_vm._v(" Пока что нет доступных вариантов ")]),_c('p',[_c('span',{staticClass:"link l-search__add-new",on:{"click":function($event){return _vm.open()}}},[_vm._v(" Добавить ")])])])]):_vm._e()]),_c('div',{staticClass:"l-search__button"},[_c('button',{staticClass:"button button--delete button--small",on:{"click":function($event){return _vm.deleteBtn()}}},[_vm._v(" Удалить ")])]),(_vm.window)?_c('Modal',{on:{"close":function($event){return _vm.close()}}},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"modal__title"},[_vm._v(" Добавить пользователя ")]),_c('div',{staticClass:"modal__content modal__form"},[_c('Field',{attrs:{"title":"Логин","error":_vm.errors.login},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}}),_c('Field',{attrs:{"title":"Пароль","type":"password","error":_vm.errors.password},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.error)?_c('div',[_c('div',{staticClass:"modal__error"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e()]),_c('div',{staticClass:"modal__footer"},[_c('button',{staticClass:"button",class:{
                                'button--request': _vm.request
                            },attrs:{"type":"submit"}},[_vm._v(" Добавить ")])])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }