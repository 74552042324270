<template>
    <div>
        <div class="modal__close"></div>
        <div class="modal" @click.self="close">
            <div class="modal__body">
                <div class="modal__wrapper">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal',
        beforeCreate() {
            document.body.style.overflow = 'hidden';
        },
        beforeDestroy() {
            document.body.style.overflow = 'auto';
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    };
</script>
