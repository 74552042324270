export default (field: any) => {
    if (!field) {
        return;
    }

    const form = field.closest('form') || field.closest('.form');

    if (!form) {
        return;
    }

    const elements = form.querySelectorAll('input, select') as any;

    try {
        elements.forEach((elem, index) => {
            if (elem === field && elements.length !== index + 1) {
                elements[index + 1].focus();
                throw new Error('BreakException');
            }
        });
    } catch (e) {
        if (e.message !== 'BreakException') {
            throw e;
        }
    }
};
