



















import Vue from 'vue';

import Field from '@/components/Field.vue';
import Validator from '@/helpers/validator';

export default Vue.extend({
    name: 'Params',
    data() {
        return {
            form: {
                name: '',
                default_value: '',
                template: ''
            },
            formRules: {
                name: 'required|min:1|max:255',
                default_value: 'min:1|max:255',
                template: 'min:1|max:255'
            },
            errors: {
                name: '',
                default_value: '',
                template: ''
            },
            formRequest: false,
            formWatcher: () => { return; }
        };
    },
    methods: {
        validate(submit = true) {
            const validate = new Validator(this.form, this.formRules).make();

            this.errors = validate.errors;

            if (validate.isValid && submit) {
                this.add();
            } else {
                this.formRequest = true;
            }
        },
        add() {
            this.$emit('add', {...this.form, key: this.uniqId});

            this.$store.commit('uniq/updateId');
            this.form = {
                name: '',
                default_value: '',
                template: ''
            };
            this.formRequest = false;
        }
    },
    computed: {
        uniqId(): number {
            return this.$store.state.uniq.lastId;
        }
    },
    watch: {
        formRequest(val) {
            if (val) {
                this.formWatcher = this.$watch('form', () => {
                    this.validate(false);
                }, {
                    deep: true
                });
            } else {
                this.formWatcher();
            }
        }
    },
    components: {
        Field
    }
});
