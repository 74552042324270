











import Vue from 'vue';

import Item from '@/components/Postback/Parameters/Item.vue';
import Form from '@/components/Postback/Parameters/Form.vue';

export default Vue.extend({
    name: 'Tree',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    methods: {
        addItem(item: any) {
            this.items.push(item);
        },
        deleteItem(index: number) {
            this.items.splice(index, 1);
        }
    },
    components: {
        Item,
        Form
    }
});
