











































































































import Vue from 'vue';

import RCP from '@/api/RCP';

import Field from '@/components/Field.vue';
import Modal from '@/components/Modal.vue';
import Validator from '@/helpers/validator';
import {ErrorResponse} from '@/types/Errors';
import nextElement from '@/helpers/nextElement';

import swal from 'sweetalert2';

export default Vue.extend({
    name: 'ExternalUsers',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        model: {
            default: () => {
                return {} as any;
            }
        }
    },
    data() {
        return {
            hover: 0,
            search: '',
            items: [] as any,
            active: false,
            window: false,
            form: {
                login: '',
                password: '',
                description: ''
            },
            formRules: {
                login: 'required|min:3|max:255',
                password: 'required|min:6|max:255'
            },
            errors: {
                login: '',
                password: ''
            },
            error: '',
            errorWatcher: () => { return; },
            request: false,
            formRequest: false
        };
    },
    created() {
        this.getList();
    },
    computed: {
        list(): any[] {
            return this.items.reduce((acc: any, cur: any) => {
                if (cur.login.toUpperCase().includes(this.search.toUpperCase())) {
                    acc.push(cur);
                }

                return acc;
            }, []);
        }
    },
    watch: {
        model: {
            handler() {
                this.search = '';
            },
            deep: true
        },
        search() {
            this.hover = 0;
        },
        active(val) {
            if (val) {
                document.addEventListener('click', this.click);
            } else {
                document.removeEventListener('click', this.click);
                const input = this.$refs.input as any;

                input.blur();
            }
        },
        formRequest() {
            this.$watch('form', () => {
                this.validate(false);
            }, {
                deep: true
            });
        },
        error(val) {
            if (val) {
                this.errorWatcher = this.$watch('form', () => {
                    this.error = '';
                }, {
                    deep: true
                });
            } else {
                if (typeof this.errorWatcher === 'function') {
                    this.errorWatcher();
                }
            }
        }
    },
    methods: {
        validate(submit = true) {
            const validator = new Validator(this.form, this.formRules).make();

            this.errors = validator.errors;

            if (validator.isValid && submit) {
                this.addUser();
            } else {
                this.formRequest = true;
            }
        },
        addUser() {
            this.request = true;

            const params = {...this.form} as any;

            if (!params.description) {
                params.description = null;
            }

            RCP({
                method: 'UserExternalAdd',
                id: 'UserExternalAdd',
                params
            }).then((response) => {
                this.items.push(response);
                this.$emit('change', response);
                this.request = false;
                this.window = false;
                this.form = {
                    login: '',
                    password: '',
                    description: ''
                };
            }).catch((e: ErrorResponse) => {
                this.error = e.message;
                this.request = false;
            });
        },
        remove(id: number) {
            if (this.request) {
                return;
            }

            swal.fire({
                title: 'Вы уверены?',
                text: 'Данное действие невозможно будет отменить',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff6161',
                cancelButtonText: 'Отмена',
                confirmButtonText: 'Удалить'
            }).then((answer: any) => {
                if (answer.value) {
                    this.request = true;

                    RCP({
                        method: 'UserExternalRemove',
                        id: 'UserExternalRemove',
                        params: {
                            user_external_id: id
                        }
                    }).then(() => {
                        if (this.model && this.model.id === id) {
                            this.$emit('change', {});
                        }

                        this.getList();
                        this.request = false;
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            swal.fire({
                                icon: 'error',
                                title: 'Ошибка',
                                text: error.message,
                                confirmButtonText: 'Ок',
                                confirmButtonColor: '#605ab9'
                            });
                        }

                        this.request = false;
                    });
                }
            });
        },
        close() {
            this.window = false;
        },
        open() {
            this.active = false;
            this.window = true;
        },
        getList() {
            RCP({
                method: 'UserExternalList',
                id: 'UserExternalList'
            }).then((response: any) => {
                this.items = response || [];
            });
        },
        blur() {
            setTimeout(() => {
                const focused = document.querySelector(':focus');
                const elem = this.$el as any;

                if (!focused || elem.contains(focused)) {
                    return;
                }

                this.active = false;
            });
        },
        focus() {
            this.active = true;
        },
        click(e: any) {
            if (!e.target) {
                this.active = false;
                return;
            }

            const input = this.$refs.input as any;

            if (this.$el.contains(e.target) && input) {
                input.focus();
            } else {
                this.active = false;
            }
        },
        up() {
            if (--this.hover < 0) {
                const count = this.items.length - 1;
                this.hover = count;
            }
        },
        down() {
            const count = this.items.length - 1;

            if (++this.hover > count) {
                this.hover = 0;
            }
        },
        choose() {
            this.$emit('change', this.list[this.hover]);
            this.active = false;
            nextElement(this.$refs.input);
        },
        deleteBtn() {
            this.$emit('change', {});
        },
        del(event) {
            if (event.code === 'Delete' || (event.code !== 'Delete' && this.search.length === 0)) {
                this.$emit('change', {});
                event.preventDefault();
            }
        }
    },
    components: {
        Field,
        Modal
    }
});
