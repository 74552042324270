








import Vue from 'vue';

export default Vue.extend({
    name: 'Switcher',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        model: {
            type: Boolean
        }
    },
    computed: {
        val: {
            get(): boolean {
                return this.model;
            },
            set(v: boolean) {
                this.$emit('change', v);
            }
        }
    }
});
