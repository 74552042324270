















import Vue from 'vue';

import Field from '@/components/Field.vue';

export default Vue.extend({
    name: 'Item',
    props: {
        item: {
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    methods: {
        deleteItem() {
            this.$emit('delete', this.index);
        }
    },
    components: {
        Field
    }
});
